@font-face {
  font-family: 'iconpack';
  src:  url('../fonts/iconpack.eot?bhj6br');
  src:  url('../fonts/iconpack.eot?bhj6br#iefix') format('embedded-opentype'),
    url('../fonts/iconpack.ttf?bhj6br') format('truetype'),
    url('../fonts/iconpack.woff?bhj6br') format('woff'),
    url('../fonts/iconpack.svg?bhj6br#iconpack') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconpack' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.icon-forbidden-smoking-signal:before {
    content: "\e900";
  }
  &.icon-restaurant:before {
    content: "\e901";
  }
  &.icon-visa-logo:before {
    content: "\e902";
  }
  &.icon-wheelchair-access:before {
    content: "\e903";
  }
  &.icon-wifi:before {
    content: "\e904";
  }
  &.icon-arrow-left:before {
    content: "\e905";
  }
  &.icon-arrow-right:before {
    content: "\e906";
  }
  &.icon-arrowright:before {
    content: "\e907";
  }
  &.icon-email:before {
    content: "\e908";
  }
  &.icon-close:before {
    content: "\e909";
  }
  &.icon-menu:before {
    content: "\e90a";
  }
  &.icon-facebook:before {
    content: "\e90b";
  }
  &.icon-instagram:before {
    content: "\e90c";
  }
  &.icon-map:before {
    content: "\e90d";
  }
  &.icon-phone:before {
    content: "\e90e";
  }
}
