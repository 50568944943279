@import "_bootstrap-sass-grid";
@import "./libs/fonts";
@import "./libs/icons";
@import "./libs/magnificPopup.css";
@import "./libs/owl.carousel.min.css";
@import "./libs/animate.min.css";
@import "./libs/leaflet.css";
@import "./libs/lightgallery.css";

body{
	font-family: 'Roboto Condensed';
	p{
		strong,b{
			font-size: 23px;
		}
	}
    /* START basic options */
    .img-responsive{
        max-width: 100%;
        width: 100%;
        height: auto;
		padding: 0px !important;
    }
	.img-article{
		margin-left: 40px;
		margin-bottom: 40px;
	}
    .text-center{
        text-align: center;
		.btn{
			margin-left: auto;
			margin-right: auto;
		}
    }
    .text-right{
        text-align: right;
    }
    .bold{
        font-weight: bold;
    }
    .p-t-30{
        padding-top: 30px !important;
    }
    .p-t-60{
        padding-top: 60px !important;
    }
    .p-b-30{
        padding-bottom: 30px;
    }
    .p-b-60{
        padding-bottom: 60px;
    }
	.p-b-120{
        padding-bottom: 120px;
    }
    .text-left{
        text-align: left;
        img{
            margin-left: 0px;
            margin-right: auto;
        }
    }
	#programy{
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.inArticle{
        .col-head{
            @extend .col-sm-3;
            @extend .col-xs-6;
            .col{
                overflow: hidden;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
				position: relative;
                .title{
					padding-bottom: 25px;
					position: absolute;
					display: block;
					bottom: 9px;
					width: 100%;
					padding-left: 25px;
					text-align: left;
					background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.8) 100%)!important;
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000', GradientType=0 )!important;
                    big{
                        font-size: 18px !important;
                        color: white !important;
                    }
                }
                .border{
					display: block;
					width: 100%;
					min-height: 280px;
					position: absolute;
					z-index: 990;
					width: calc(100% - 20px);
					margin-left: 10px;
					margin-top: 10px;
					border: 2px solid #fff!important;
                }
            }
        }
    }
	.reference{
        @extend .row;
        //display: flex;
        margin-top: 60px;
        margin-bottom: 30px;
        .img{
            @extend .col-sm-12;
            @extend .col-md-8;
            a{
                @extend .col-xs-3;
                @extend .col-sm-3;
                margin-bottom: 30px;
                display: block;
                position: relative;
                overflow: hidden;
                img{
                    @extend .col-xs-12;
                    padding: 0px;
                    //-webkit-transition: transform .5s;
                    //transition: transform .5s;
                }
                &:first-child{
                    @extend .col-xs-12;
                    @extend .col-sm-9;
                }
                &:hover{
                    img{
                        //transform: scale(1.2);
                    }
                }
                .play{
                    width: 70px;
                    height: 70px;
                    position: absolute;
                    display: block;
                    z-index: 900;
                    left: 45%;
                    top: 45%;
                    text-align: center;
                    line-height: 50px;
                    border-radius: 100%;
                    background-color: white;
                    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
					background-image: url(../img/play-button-2.svg);
					background-size: 30px 30px;
					background-position: center;
					background-repeat: no-repeat;
                    span{
                        margin-top: 4px;
                        font-size: 50px;
                        color: #0b285b;
                        transform: rotate(270deg);
                        margin-left: 3px;
                    }
                }
                &:nth-child(n+5){
                    display: none;
                }
            }
        }
        .description{
            @extend .col-sm-12;
            @extend .col-md-4;
            //display: table;
            big{
                font-size: 26px;
                font-weight: bold;
                color: #0b285b;
            }
            h3{
                font-size: 20px;
                text-transform: none;
                padding: 0px;
                padding-top: 10px;
                padding-bottom: 10px;
                margin: 0px;
                color: black;
                font-weight: normal;
            }
			ul{
				margin-left: 20px;
			}
            h4{
                font-size: 20px;
                text-transform: none;
                margin: 0px;
                padding: 0px;
            }
            .info{
                border-top: 1px dashed rgba(0,0,0,.3);
                padding-top: 15px;
                padding-bottom: 15px;
                border-bottom: 1px dashed rgba(0,0,0,.3);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content:space-around;
                width: 100%;
                margin-bottom: 25px;
                div{
                    text-align: center;
                    font-weight: bold;
                    img{
                        height: 24px;
                        float: left;
                        margin-right: 10px;
                        path{
                            fill:red;
                            background-color: red;
                        }
                    }
                }
            }
            .desc{
                position: relative;
                bottom: -20px;
            }
        }
    }
    .row.display-flex {
        display: flex;
        flex-wrap: wrap;
        &::before{
            content: "";
            display: none;
        }
    }
    .row.display-flex > [class*='col-'] {
        display: flex;
        flex-direction: column;
        float: none;
    }
    .hidden.active{
        display: block !important;
    }
	h1,h2,h3{
		text-transform: uppercase;
	}
    h1,h2,h3,h4,h5{
        color: black;
        font-size: 50px;
        line-height: 55px;
        font-weight: 900;
		margin: 0px;
		padding: 0px;
    }
	h3{
		font-size: 30px;
	}
    .noPadding{
        padding: 0px !important;
    }
    .noPaddingLeft{
        padding-left: 0px !important;
    }
    .noPaddingRight{
        padding-right: 0px !important;
    }
    .blank{
        width: 100%;
        height: 80px;
    }
    /* START basic options */
    .textColor{
        color: #e51a4b;
    }
    a{
        color: #e51a4b;
        cursor: pointer;
        text-decoration: none;
    }
    ul{
        -webkit-margin-before: 0px;
        -webkit-margin-after: 0px;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 0px;
    }
    ul.default{
        padding: 0px;
        margin: 0px;
        margin-bottom: 25px;
        list-style: none;
        font-size: 15px;
        li{
            padding-left: 20px;
            &::before{
                content: "";
                width: 8px;
                height: 8px;
                background-color: #ea2658;
                border-radius: 30px;
                position: absolute;
                display: block;
                margin-top: 10px;
                margin-left: -20px;
            }
        }
    }
	.owl-slider{
		.owl-nav{
			.owl-prev,.owl-next{
				position: absolute;
				margin-top: -400px;
				left: 15px;
				color: #9fabb2;
				&.owl-next{
					left: auto;
					right: 15px;
				}
				&:hover{
					color: #e51a4b;
				}
			}
			span{
				display: block;
				background-color: #fcfbfd;
				width: 60px;
				height: 60px;
				border-radius: 60px;
				line-height: 60px;
				text-align: center;
				border: 2px solid white;
			}
		}
	}
	.services{
		font-weight: bold;
		font-size: 18px;
		line-height: 60px;
		padding-left: 50px;
		display: block;
		i{
			position: absolute;
			font-size: 30px;
			margin-left: -50px;
			margin-top: 18px;
			color: #e51a4b
		}

	}
	/* START article */
	.article{
		display: block;
		padding-top: 15px;
		padding-bottom: 15px;
		color: rgba(0,0,0,.6);
		span.img{
			width: 100%;
			display:block;
			overflow: hidden;
			position: relative;
			.bghover{
				display: block;
				width: 100%;
				position: absolute;
				background-color: rgba(229,26,75,.7);
				z-index: 999;
				height: 300px;
				line-height: 300px;
				left: -100%;
				-webkit-transition: left .5s; /* Safari */
    			transition: left .5s;
				.bt{
					background-color: white;
					color: black;
					text-transform: uppercase;
					padding: 25px;
					border: 1px solid white;
				}
			}
			&:hover{
				.bghover{
					left: 0px;
				}
			}
		}
		h3{
			color: black;
			font-size: 30px;
			line-height: 35px;
			display: block;
			padding-top: 15px;
		}
		p{
			padding-bottom: 15px;
		}
		@media screen and (max-width:474px) {
			&{
				padding-bottom: 50px;
			}
		}
	}
	/* END article*/
    /* START btns */
    .btn{
        background-color: #c00039;
        color: white;
        padding: 25px;
        font-size: 17px;
        padding-left: 35px;
        padding-right: 35px;
		text-transform: uppercase;
    }
    /* END btns */
	/* START virtualni prohlidka */
	#virtualni-prohlidka{
		.bg{
			background-image: url(../img/virtualni-prohlidkaBg.jpg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
			padding-top: 200px;
			padding-bottom: 200px;
		}
	}
	/* END virtualni prohlidka*/
	/* START cristal dinner */
	#crystal-dinner{
		a{
			border-bottom: 1px solid #e51a4b;
			&:hover{
				border-bottom: none;
			}
		}
		strong{
			font-size: 18px;
		}
		ul{
			list-style: none;
			margin-bottom: 30px;
			li{
				padding-left: 20px;
				&::before{
					content: "";
					position: absolute;
					border-radius: 10px;
					margin-right: 5px;
					display: block;
					left: 15px;
					margin-top: 9px;
					width: 10px;
					height: 10px;
					background-color: #e51a4b;
				}
			}
		}
		.price{
			border: 2px dashed rgba(192,0,57,.3);
			color: #c00039;
			padding: 15px;
			text-align: center;
		}
		a.btn{
			display: block;
			width: 100%;
			text-align: center;
			font-weight: bold;
			text-shadow: 1px 1px black;
			margin-top: 15px;
			margin-bottom: 10px;
			font-size: 26px;
			border: none !important
		}
		small{
			text-align: center;
			display: block;
			line-height: 15px;
		}
		.photo{
			margin-top: 25px;
			display: block;
			border: none;
		}
	}
	/* END cristal dinner*/
	/* START kontakt */
	#kontakt{
		#googleMap{
			height: 500px;
		}
		h2{
			font-size: 40px;
		}
		p{
			color: #747474;
		}
		.contact{
			display: block;
			color: #c00039;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 20px;
			i{
				margin-right: 15px;
				margin-bottom: 15px;
				margin-top: 4px;
			}
		}
		.contact-box{
            position: absolute;
            margin-top: 50px;
            background-color: white;
			z-index: 990;
            @media screen and (max-width:1010px) {
                position: static;
                margin-top: 15px;
                -webkit-box-shadow:none;
                -moz-box-shadow:none;
                box-shadow:none;
                border-radius: none;
            }
            h3{
                font-size: 24px;
            }
            .lines{
                padding-top: 10px;
                padding-bottom: 10px;
                color: #323232;
                b{
                    color: #97c1da;
                }
                i{
                    color: #97c1da;
                    font-size: 30px;
                    margin-right: 15px;
                    margin-top: 10px;
                    margin-left: 15px;
                }
            }

        }
	}
	/* END kontakt */
    /* START footer options */
	#footer{
		padding-top: 30px;
		padding-bottom: 30px;
		font-size: 15px;
		color: #9d9d9d;
        a{
            color: #c00039;
        }
    }
    /* END footer options */
}
